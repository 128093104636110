*{
    font-family: "Poppins";
    margin: 0px;
}
p{
    font-family: "Poppins";
}

.sale {
	position: relative;
  top:-75px;
  right: -150px;
	background: orange;
  box-shadow: 0px 0px 56px 1px orange;
	color: white;
	height: 2.5rem;
	width: 2.5rem;
	text-align: center;
	vertical-align: middle;
	line-height: 2.5rem;
	transform: rotate(-10deg);
	animation: beat .4s ease infinite alternate;
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
	&:before,
	&:after {
		content:"";
		position: absolute;
		background: inherit;
		height: inherit;
		width: inherit;
		top: 0;
		left: 0;
		z-index: -1;
		transform: rotate(30deg);
	}
	&:after {
		transform: rotate(60deg);
	}
}

@keyframes beat {
	from {	transform: rotate(-20deg) scale(1); }
	to {	transform: rotate(-20deg) scale(1.1); }
}

.plus10-animation {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: orange;
    color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 20px;
    animation: fadeout 1.5s ease-out;
  
  }
  
  @keyframes fadeout {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
  
  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-10px);
    }
    50% {
      transform: translateX(10px);
    }
    75% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  *::-webkit-scrollbar {
    display: none;  /* Chrome, Safari */
  }

  /* HTML: <div class="loader"></div> */
.loader {
  margin-top: 50px;
  width: 50px;
  aspect-ratio: 1;
  border-radius: 100%;
  border: 8px solid orange;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}
.loader_mini {
  margin-top: 0px;
  width: 10px;
  aspect-ratio: 1;
  border-radius: 100%;
  border: 8px solid orange;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}
@keyframes l20-1{
   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{ 
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}

button{
  transition: 0.3s;
}
button:hover{
  transform: translateY(-3px);
}
.cardButton{
  transition: 0.3s;
}
.cardButton:hover{
  transform: translateY(2px);
}
#heart-unclicked{
  color: gainsboro;
  transition: 0.2s;
}
#heart-clicked{
  color: hotpink;
  text-shadow: 1px 1px 10px hotpink;
}
#heart-unclicked:hover{
  color: hotpink;
  animation: shake-heart .4s;
  text-shadow: 1px 1px 10px hotpink;
}

@keyframes shake-heart {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-3px);
  }
  50% {
    transform: translateX(3px);
  }
  75% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(0);
  }
}

/* src/CareerCard.css */
.career-card {
  border: 1px solid gainsboro;
  padding: 20px;
  margin: 20px;
  width: 1300px;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.career-title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.career-description {
  font-size: 1em;
  margin-bottom: 20px;
}

.apply-button {
  background-color:rgb(0, 0, 0);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin: -5px
}

.apply-button:hover {
  background-color:orange;
}

/* src/CareersPage.css */
.careers-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  overflow: scroll;
}

.card {
  width: 370px;
  height: 80vh;
  margin: 50px 0px;
  transition: 0.1s;
}

.videoCard {
  width: 370px;
  height: 80vh;
  background-size: fit;
  margin: 50px 0px;
  background-position: center;
  transition: background-size 0.5s ease-in-out; /* For smooth transition */
}

.videoCard.zoom {
  animation: zoom 3s infinite alternate; /* Start zoom animation */
  background-size: cover;

}

.image-container {
  transition: transform 0.5s ease-in-out; /* For smooth zoom effect */
  height: 100%; /* Make sure it fills the card */
  width: 100%;
}

.image-container.zoom {
  transform: scale(1.1); /* Zoom in effect */
}

@keyframes zoomIn {
  0% {
    transform: scale(1); /* Initial size */
  }
  100% {
    transform: scale(1.1); /* Final zoomed size */
  }
}


@keyframes zoom {
  0% {
    background-size: 100%; /* Original size */
    background-size: cover;

  }
  100% {
    background-size: 110%; /* Zoom in */
    background-size: cover;

  }
}

@media (max-width:568px) {
 .card{
  width: 84vw;
  height: 87vh;
  margin: 10px 0px;
 }
 .videoCard{
  width: 84vw;
  height: 87vh;
  margin: 10px 0px;
 }
}
